export const environment = {
  // eslint-disable-next-line no-undef
  appVersion: require('../../package.json').version,
  production: true,
  apiUrl: 'https://api.wipperoz.com',
  appUrl: 'https://app.wipperoz.com',
  landingUrl: 'https://www.wipperoz.com',
  authUrl: 'auth.wipperoz.com',
  suffixEmailTemplate: '_prd',
  cloudFrontS3Url: 'https://content.wipperoz.com',
  projectVersion: 'us-east-1',
  poolWebClientId: '711la4ui4kvp5jafrl2k4oaume',
  userPoolId: 'M6bl5FIA7',
  identityPoolId: '950c65fb-759d-4993-9723-b02ef8fd2670',
  stripePublishableKey: 'pk_live_GRf3T4sKniHaRd53UbhhCWj500qXpH98we',
  googleAnalyticsKey: 'UA-175620265-1',
  allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png'],
  maxImageSize: 10485760,
  recaptcha: '6LegUWgaAAAAADii_bDRJjXgpWXRQWK67VQE32zX',
  recaptchaScore: 0.69,
  year: 2022,
  clickLink: 'https://click.wipperoz.com',
  wsEndpoint: 'wss://1mpvf0jyrb.execute-api.us-east-1.amazonaws.com/prd',
  providersThreshold: 0,
  googleTagManager: 'GTM-PF59D69'
};
