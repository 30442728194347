import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WipperozProgressBarModule } from 'app/wipperoz-core/components';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MetafrenzyModule } from 'ngx-metafrenzy';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'WipperozServerLanding' }),
    BrowserAnimationsModule,
    HttpClientModule,
    MetafrenzyModule.forRoot(),
    WipperozProgressBarModule,
    AppRoutingModule
  ],
  bootstrap: [AppComponent],
  exports: [WipperozProgressBarModule]
})
export class AppModule {}
