export class IpLocation {
  country: string;
  currencyCode: string;
  language: string;
  countryCode: string;
  ip: string;
  firstSeenOn: Date;

  constructor(ipLocation?: IpLocation | any) {
    if (ipLocation !== undefined) {
      this.country =
        ipLocation.locationData !== undefined && ipLocation.locationData.location.country.name !== undefined
          ? ipLocation.locationData.location.country.name
          : '';
      this.currencyCode = ipLocation.locationData !== undefined ? ipLocation.locationData.currency.code : 'USD';
      this.language = ipLocation.locationData !== undefined ? ipLocation.locationData.location.language.code : 'en';
      this.countryCode = ipLocation.locationData !== undefined ? ipLocation.locationData.location.country.code : '';
      this.ip = ipLocation.ip;
      this.firstSeenOn = ipLocation.createdOn;
    }
  }
}
