import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(private _httpClient: HttpClient) {}
  logError(intro: string, message: string, stack?: string | null): Promise<any> {
    if (stack === undefined) {
      stack = null;
    }
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<any>(
          `${environment.apiUrl}/wipperoz-logger/landing`,
          { intro: intro, log: message, stack: stack },
          {
            reportProgress: true,
            observe: 'response',
            responseType: 'json'
          }
        )
        .subscribe(
          () => {
            resolve(true);
          },
          () => {
            reject(false);
          }
        );
    });
  }
}
