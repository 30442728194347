export class CurrentLocation {
  lng: string | null;
  lat: string | null;
  suburb: string;
  useCurrentLocation: boolean;
  lastUpdated: number;

  constructor(currentLocation?: CurrentLocation | any) {
    currentLocation = currentLocation || ({} as CurrentLocation);
    this.lng = currentLocation.lng || '';
    this.lat = currentLocation.lat || '';
    this.suburb = currentLocation.suburb || '';
    this.useCurrentLocation = currentLocation.userCurrentLocation || false;
    this.lastUpdated = currentLocation.lastUpdated || new Date().getTime();
  }
}
