import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WipperozProgressBarService } from 'app/wipperoz-core/components/progress-bar/progress-bar.service';

@Component({
  selector: 'wipperoz-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WipperozProgressBarComponent implements OnInit, OnDestroy {
  visible: boolean;

  private _unsubscribeAll: Subject<any>;
  constructor(private _wipperozProgressBarService: WipperozProgressBarService) {
    this._unsubscribeAll = new Subject();
  }
  ngOnInit(): void {
    this._wipperozProgressBarService.visible.pipe(takeUntil(this._unsubscribeAll)).subscribe((visible) => {
      this.visible = visible;
    });
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
