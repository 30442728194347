/* eslint-disable camelcase */
import { environment } from './environments/environment';

const awsmobile = {
  aws_project_region: environment.projectVersion,
  aws_cognito_identity_pool_id: environment.projectVersion + ':' + environment.identityPoolId,
  aws_cognito_region: environment.projectVersion,
  aws_user_pools_id: environment.projectVersion + '_' + environment.userPoolId,
  aws_user_pools_web_client_id: environment.poolWebClientId,
  oauth: {
    domain: `${environment.authUrl}`,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
    redirectSignIn: `${environment.appUrl}/en/auth/login`,
    redirectSignOut: `${environment.appUrl}/en/auth/login`
  }
};

export default awsmobile;
