import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CurrentLocation } from '../types/location/current-location.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

const GEOLOCATION_ERRORS = {
  'errors.location.unsupportedBrowser': 'Browser does not support location services',
  'errors.location.permissionDenied': 'You have rejected access to your location',
  'errors.location.positionUnavailable': 'Unable to determine your location',
  'errors.location.timeout': 'Service timeout has been reached'
};

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {
  currentLocation: CurrentLocation;
  currentLocationChanged: BehaviorSubject<any>;
  suburbChanged: BehaviorSubject<any>;
  locationChanged = false;
  watchId = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.currentLocation = new CurrentLocation();
    this.currentLocationChanged = new BehaviorSubject<any>(this.currentLocation);
    this.suburbChanged = new BehaviorSubject<any>(this.currentLocation);
  }

  /**
   * Obtains the geographic position, in terms of latitude and longitude coordinates, of the device.
   * @param {Object} [opts] An object literal to specify one or more of the following attributes and desired values:
   *   - enableHighAccuracy: Specify true to obtain the most accurate position possible, or false to optimize in favor of performance and power consumption.
   *   - timeout: An Integer value that indicates the time, in milliseconds, allowed for obtaining the position.
   *              If timeout is Infinity, (the default value) the location request will not time out.
   *              If timeout is zero (0) or negative, the results depend on the behavior of the location provider.
   *   - maximumAge: An Integer value indicating the maximum age, in milliseconds, of cached position information.
   *                 If maximumAge is non-zero, and a cached position that is no older than maximumAge is available, the cached position is used instead of
   *                 obtaining an updated location.
   *                 If maximumAge is zero (0), watchPosition always tries to obtain an updated position, even if a cached position is already available.
   *                 If maximumAge is Infinity, any cached position is used, regardless of its age, and watchPosition only tries to obtain an updated position
   *                if no cached position data exists.
   * @returns {Observable} An observable sequence with the geographical location of the device running the client.
   */
  public getLocation(opts?: PositionOptions): Observable<any> {
    return new Observable((observer) => {
      if (isPlatformBrowser(this.platformId)) {
        window.navigator.geolocation.clearWatch(this.watchId);
        if (window.navigator && window.navigator.geolocation) {
          this.watchId = window.navigator.geolocation.watchPosition(
            (position) => {
              if (
                position.coords.longitude.toString() !== this.currentLocation.lng ||
                position.coords.latitude.toString() ||
                this.currentLocation.lat
              ) {
                if (this.currentLocation.lng !== position.coords.longitude.toString()) {
                  this.currentLocation.lng = position.coords.longitude.toString();
                  this.locationChanged = true;
                }
                if (this.currentLocation.lat !== position.coords.latitude.toString()) {
                  this.currentLocation.lat = position.coords.latitude.toString();
                  this.locationChanged = true;
                }
                this.currentLocation.useCurrentLocation = true;

                if (this.locationChanged) {
                  this.locationChanged = false;
                  this.currentLocation.lastUpdated = new Date().getTime();
                  this.currentLocationChanged.next(this.currentLocation);
                }
              }

              observer.next(this.currentLocation);
              observer.complete();
            },
            (error) => {
              switch (error.code) {
                case 1:
                  observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
                  observer.complete();
                  break;
                case 2:
                  observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
                  observer.complete();
                  break;
                case 3:
                  observer.error(GEOLOCATION_ERRORS['errors.location.timeout']);
                  observer.complete();
                  break;
              }
            },
            opts
          );
        } else {
          this.currentLocation.lng = null;
          this.currentLocation.lat = null;
          this.currentLocation.useCurrentLocation = false;
          this.currentLocationChanged.next(this.currentLocation);
          observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
        }
      }
    });
  }

  public clearWatch(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.navigator.geolocation.clearWatch(this.watchId);
    }
  }

  setSuburb(suburb: string): void {
    this.currentLocation.suburb = suburb;
    this.suburbChanged.next(this.currentLocation);
  }

  getCurrentCoordinates(): { lat: string; lng: string } {
    return { lat: this.currentLocation.lat, lng: this.currentLocation.lng };
  }

  getCurrentLocation(): CurrentLocation {
    return this.currentLocation;
  }
}
