import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { WipperozAlertService } from './alert.service';
import { WipperozProgressBarService } from 'app/wipperoz-core/components/progress-bar/progress-bar.service';
import { Observable } from 'rxjs';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class WipperozApiService {
  constructor(
    private _httpClient: HttpClient,
    private _wipperozAlertService: WipperozAlertService,
    private _wipperozProgressBarService: WipperozProgressBarService,
    private loggerService: LoggingService
  ) {}

  public get<T>(url: string, params?: HttpParams): Observable<T> | undefined {
    if (!url) {
      return;
    }

    if (!params) {
      params = new HttpParams();
    }

    const req = new HttpRequest('GET', `${environment.apiUrl}` + url, {
      reportProgress: true,
      params: params,
      observe: 'body',
      responseType: 'json'
    });

    return this._httpClient.request(req).pipe(
      map(
        (event: HttpEvent<any>) => {
          return this.onHttpEvent(event);
        },
        (err: HttpErrorResponse) => {
          this.onHttpErrorResponse(err);
        }
      )
    );
  }

  public put<T>(url: string, body: T): Observable<any> | undefined {
    if (!url) {
      return;
    }
    return this._httpClient.put<any>(`${environment.apiUrl}` + url, body, {
      reportProgress: true,
      observe: 'response',
      responseType: 'json'
    });
  }

  public post<T>(url: string, body: T): Observable<any> | undefined {
    if (!url) {
      return;
    }

    return this._httpClient.post<any>(`${environment.apiUrl}` + url, body, {
      reportProgress: true,
      observe: 'response',
      responseType: 'json'
    });
  }

  public delete<T>(url: string, params?: HttpParams): Observable<T> | undefined {
    if (!url) {
      return;
    }

    if (!params) {
      params = new HttpParams();
    }

    const req = new HttpRequest('DELETE', `${environment.apiUrl}` + url, {
      reportProgress: true,
      params: params,
      observe: 'body',
      responseType: 'json'
    });

    return this._httpClient.request(req).pipe(
      map(
        (event: HttpEvent<any>) => {
          return this.onHttpEvent(event);
        },
        (err: HttpErrorResponse) => {
          this.onHttpErrorResponse(err);
        }
      )
    );
  }

  private onHttpEvent(event: HttpEvent<any>): any {
    switch (event.type) {
      case HttpEventType.Sent:
        this._wipperozProgressBarService.show();
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.UploadProgress:
        // const percentDone = Math.round(100 * event.loaded / event.total);
        break;
      case HttpEventType.DownloadProgress:
        // const kbLoaded = Math.round(event.loaded);
        break;
      case HttpEventType.Response:
        this._wipperozProgressBarService.hide();
        return event.body;
    }
  }

  private onHttpErrorResponse(err: HttpErrorResponse): void {
    this._wipperozProgressBarService.hide();
    this._wipperozAlertService.error(
      $localize`:@@errorHttpErrorResponseMessage:Error from the backend. A message was sent to Wipperoz team to investigate the matter.`,
      false
    );
    this.loggerService.logError('wipperoz-api.service onHttpErrorResponse error ', JSON.stringify(err));
  }
}
