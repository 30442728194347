import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { MetafrenzyService } from 'ngx-metafrenzy';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { delay, filter, mergeMap, retryWhen, take, takeUntil } from 'rxjs/operators';
import { WipperozAlertService } from './wipperoz-core/services/alert.service';
import { CurrentLocationService } from './wipperoz-core/services/current-location.service';
import { IpLocationService } from './wipperoz-core/services/ip-location.service';
import { WipperozGeolocationService } from './wipperoz-core/services/location.service';
import { IpLocation } from './wipperoz-core/types/location/ip-location.model';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  wipperozConfig: any;
  navigation: any;
  lat = '';
  lng = '';
  availableLanguages = ['en', 'es'];
  location: any;
  routerSubscription: any;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(LOCALE_ID) private localeId: string,
    private _wipperozAlertService: WipperozAlertService,
    private _ipLocationService: IpLocationService,
    private currentLocationService: CurrentLocationService,
    private locationService: WipperozGeolocationService,
    private readonly metafrenzyService: MetafrenzyService,
    private router: Router
  ) {
    this.metafrenzyService.setMetaTag('viewport', 'width=device-width, initial-scale=1');
    this.metafrenzyService.setMetaTag('referrer', 'origin');
    this.metafrenzyService.setMetaTag('image', 'assets/images/logos/512w/w_wipperoz.png');
    this.metafrenzyService.setMetaTag('robots', 'index, follow');
    this.metafrenzyService.setMetaTag('author', 'Wipperoz pty ltd');
    this.metafrenzyService.setMetaTag('date', new Date().toLocaleDateString('en-AU'));
    this.metafrenzyService.setMetaTag('charset', 'UTF-8');
    this.metafrenzyService.setMetaTag('fb:app_id', '282676226612024');
    this.metafrenzyService.setMetaTag('og:type', 'website');

    if (isPlatformBrowser(this.platformId)) {
      this.locationService.setInitialAppLanguage(this.localeId, window.location.pathname);
    }

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.recallJsFuntions();

      this._ipLocationService
        .getLocation()
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter((x: any) => {
            return x !== undefined;
          }),
          retryWhen((errors) => errors.pipe(delay(3000), take(2)))
        )
        .toPromise()
        .then((x) => {
          this._ipLocationService.ipLocation = new IpLocation(x.items[0]);
          this._ipLocationService.ipLocationChanged.next(x);
        });
      this.locationService.onLanguageChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
        if (data.language !== undefined && data.language !== this.localeId) {
          this.locationService.changeLocale(data.language);
        }
      });

      this.currentLocationService.currentLocationChanged
        .pipe(
          takeUntil(this._unsubscribeAll),
          mergeMap((x) => {
            if (x.lat !== '' && !this.locationService.gettingLocation && x.lat !== this.lat && x.lng !== this.lng) {
              this.lng = x.lng;
              this.lat = x.lat;
              return this.locationService.geoGeocode(this.lat + ',' + this.lng);
            } else {
              return new Observable();
            }
          })
        )
        .subscribe((data: any) => {
          if (data !== undefined) {
            this.currentLocationService.setSuburb(data.results[0].components.suburb);
          }
        });

      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');

      this.subscriptions.push(
        this.onlineEvent.subscribe(() => {
          this.connectionStatusMessage = $localize`:@@connectionReestablished:Back online!`;
          this.connectionStatus = 'online';
          this._wipperozAlertService.success(this.connectionStatusMessage, false);
        })
      );

      this.subscriptions.push(
        this.offlineEvent.subscribe(() => {
          this.connectionStatusMessage = $localize`:@@connectionLostMessage:Connection lost! You are not connected to internet`;
          this.connectionStatus = 'offline';
          this._wipperozAlertService.error(this.connectionStatusMessage, true);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
