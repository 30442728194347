import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WipperozProgressBarComponent } from './progress-bar.component';

@NgModule({
  declarations: [WipperozProgressBarComponent],
  imports: [CommonModule, RouterModule],
  exports: [WipperozProgressBarComponent]
})
export class WipperozProgressBarModule {}
