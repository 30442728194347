import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WipperozProgressBarService {
  private _visible: BehaviorSubject<boolean>;

  constructor(private _router: Router) {
    this._init();
  }
  get visible(): Observable<any> {
    return this._visible.asObservable();
  }
  show(): void {
    this._visible.next(true);
  }
  hide(): void {
    this._visible.next(false);
  }
  private _init(): void {
    this._visible = new BehaviorSubject(false);
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.show();
    });
    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel
        )
      )
      .subscribe(() => {
        this.hide();
      });
  }
}
