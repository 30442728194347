import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MetafrenzyGuard } from 'ngx-metafrenzy';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  {
    path: 'press',
    loadChildren: () => import('./press/press.module').then((m) => m.PressModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module').then((m) => m.PricingModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  {
    path: 'servicing',
    loadChildren: () => import('./servicing/servicing.module').then((m) => m.ServicingModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        tags: [
          {
            name: 'og:image',
            content: $localize`:@@metadataMainOgImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/ogimage.png`
          },
          { name: 'twitter:creator', content: '@wipperoz' },
          {
            name: 'twitter:image',
            content: $localize`:@@metadataMainTwitterImage:https://com-wipperoz-content-prd.s3.amazonaws.com/public/landing/meta/twitterimage.png`
          },
          { name: 'twitter:card', content: 'summary_large_image' }
        ]
      }
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
