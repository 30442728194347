import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { WipperozApiService } from './wipperoz-api.service';

import { WipperozGeolocation } from 'app/wipperoz-core/types';
import { tap, filter, catchError } from 'rxjs/operators';
import { WipperozAlertService } from './alert.service';
import { LoggingService } from './logging.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class WipperozGeolocationService {
  locations: WipperozGeolocation[];
  availableLanguages = ['en', 'es'];
  language = '';
  url = '';
  gettingLocation = false;
  onLanguageChanged: BehaviorSubject<any>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _wipperozApiService: WipperozApiService,
    private wipperozAlertService: WipperozAlertService,
    private loggingService: LoggingService
  ) {
    this.onLanguageChanged = new BehaviorSubject<any>({ language: this.language, url: this.url });
  }

  changeLocale(selectedLangCode: string, url?: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.availableLanguages.includes(selectedLangCode)) {
        this.setInitialAppLanguage(selectedLangCode, '');
        if (url === undefined) {
          window.location.href = `${window.location.origin}/${selectedLangCode}`;
        } else {
          window.location.href = `${window.location.origin}/${selectedLangCode}${url}`;
        }
      }
    }
  }

  geoGeocode(lookupInput: string, language?: string): Observable<any> | undefined {
    this.gettingLocation = true;
    let params = new HttpParams();

    params = params.append('limit', '15');
    params = params.append('q', lookupInput.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    params = params.append('language', language || this.language);
    params = params.append('pretty', '1');

    const locationResult = this._wipperozApiService.get<any>('/geocode/landing', params)?.pipe(
      filter((data: any) => {
        return data !== undefined;
      }),
      tap(
        (data) => {
          if (data.status === 400) {
            this.gettingLocation = false;
            return null;
          } else {
            this.gettingLocation = false;
            return data;
          }
        },
        (err) => {
          this.gettingLocation = false;
          this.wipperozAlertService.error(
            $localize`:@@geoGeocodeError:Error getting geolocation. A message was sent to Wipperoz team to investigate the matter.`
          );
          this.loggingService.logError('location.service: geoGeocode error', JSON.stringify(err));
          return null;
        }
      ),
      catchError((val) => of(val))
    );
    return locationResult;
  }

  setAppLanguage(language: string, url?: string): void {
    this.language = language;
    this.onLanguageChanged.next({ language: this.language, url: url });
  }

  setInitialAppLanguage(language: string, url: string) {
    this.language = language;
    this.url = url;
  }
}
