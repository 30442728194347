import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

import { WipperozApiService } from './wipperoz-api.service';
import { IpLocation } from '../types/location/ip-location.model';

@Injectable({ providedIn: 'root' })
export class IpLocationService {
  ipLocationChanged: BehaviorSubject<any>;
  ipLocation: IpLocation;

  constructor(private _wipperozApiService: WipperozApiService, private http: HttpClient) {
    this.ipLocation = new IpLocation();
    this.ipLocationChanged = new BehaviorSubject<IpLocation>(this.ipLocation);
  }

  getLocation(): Observable<any> {
    return this._wipperozApiService.get<any>('/ip-location/landing');
  }
}
